import SaveAltRoundedIcon from '@material-ui/icons/SaveAltRounded';
import { omit, pick } from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getBaseUrl } from '@core/base-url';
import { useConfig } from '@core/contexts/ConfigContext';
import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { marketplaceMembersActions } from '@redux/reducers/marketplace-members/marketplaceMembersReducer';
import { RootState } from '@redux/reducers/rootReducer';
import { marketplaceMembersSelectors } from '@redux/selectors/marketplace-members/marketplaceMembers';
import { MarketplaceMembersViewType } from '@redux/types/marketplace-members/marketplaceMembersTypes';
import { Button } from '@shared/components/button';
import { FilterButton } from '@shared/components/buttons/filter-button';
import { CollapsingSearch } from '@shared/components/collapsing-search';
import { Dropdown } from '@shared/components/dropdown';
import { Flex } from '@shared/components/flex';
import { Loading } from '@shared/components/loading';
import { Navigation, TabConfig, TabModes } from '@shared/components/navigation';
import { NoData, NoDataTheme } from '@shared/components/no-data';
import { PageHeader } from '@shared/components/page-header';
import { Pagination } from '@shared/components/pagination';
import { Option, SingleSelectOnChange } from '@shared/components/select/Select.types';
import { SingleSelect } from '@shared/components/select/single-select';
import { ViewButtons, ViewButtonsConfig } from '@shared/components/view-buttons';
import { Societies, societiesHash } from '@shared/constants/societies';
import { NoDataIcon } from '@shared/icons/no-data/NoDataIcon';
import { getQueries, getQueriesAsSearch, getQueryValueString, getSocietiesState } from '@shared/utils/common';

import { MarketplaceMemberCard } from './components/marketplace-member-card';
import { MarketplaceMembersFilterDrawer } from './components/marketplace-members-filter-drawer';
import { styles } from './MarketplaceMembersIndex.styles';

export const marketplaceMembersParamKeys = [
  'committee_regions',
  'companies',
  'email',
  'limit',
  'name',
  'order',
  'page',
  'regions',
  'role',
  'search',
  'societies',
] as const;
export type MarketplaceMembersFilteringParams = AcceptedParams<typeof marketplaceMembersParamKeys>;

const sortOptions: Option<string>[] = [
  { id: 'name-asc', label: 'First Name (A-Z)' },
  { id: 'name-desc', label: 'First Name (Z-A)' },
  { id: 'surname-asc', label: 'Last Name (A-Z)' },
  { id: 'surname-desc', label: 'Last Name (Z-A)' },
  { id: 'company-asc', label: 'Company (A-Z)' },
  { id: 'company-desc', label: 'Company (Z-A)' },
  { id: 'society_date-desc', label: 'Date Added' },
];

const viewButtonConfig: ViewButtonsConfig<MarketplaceMembersViewType>[] = [
  {
    text: 'All Members',
    view: MarketplaceMembersViewType.all,
  },
  {
    text: 'Committee',
    view: MarketplaceMembersViewType.committee,
  },
];

export interface MarketplaceMembersIndexProps extends WithStyles<typeof styles> {
  url: string;
}

const MarketplaceMembersIndexComponent: FC<MarketplaceMembersIndexProps> = ({ classes, url }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { user } = useConfig();
  const { societyIds } = getSocietiesState(user);

  const state = useSelector(marketplaceMembersSelectors.getState);
  const { browserEventsCount, urlCount } = useSelector((state: RootState) => state.browserEvent);

  const [params, setParams] = useState<MarketplaceMembersFilteringParams | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<string>('society_date-desc');
  const [search, setSearch] = useState<string | undefined>();
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [role, setRole] = useState<MarketplaceMembersViewType>(MarketplaceMembersViewType.all);
  const [filtersCount, setFiltersCount] = useState<number>(0);

  const hasSocietiesParam = useMemo(() => getQueryValueString('societies', params), [params]);

  useEffect(() => {
    setParams(pick(getQueries(), marketplaceMembersParamKeys) as MarketplaceMembersFilteringParams);
  }, [browserEventsCount, urlCount]);

  useEffect(() => {
    if (!params) {
      return;
    }

    if (params.order) {
      setOrderBy(getQueryValueString('order', params));
    }
    if (params.role) {
      setRole(getQueryValueString('role', params) as MarketplaceMembersViewType);
    }

    const _search = getQueryValueString('search', params);
    setSearch(_search);
    setSearchOpen(!!_search);

    setCurrentPage(parseInt(getQueryValueString('page', params) || '1'));

    setFiltersCount(Object.keys(omit(params, ['page', 'order', 'role', 'search'])).length);

    dispatch(
      marketplaceMembersActions.getDataRequest({
        ...params,
        limit: '24',
        ...(params?.societies && role === MarketplaceMembersViewType.committee && { role }),
      })
    );
  }, [params]);

  const handleSortingChange: SingleSelectOnChange = (e, order) => {
    history.push(`${url}${getQueriesAsSearch({ ...params, order: order })}`);
  };

  const handlePageChange = (page: number) => {
    history.push(`${url}${getQueriesAsSearch({ ...params, page: page > 1 ? page : undefined })}`);
  };

  const handleOpenFiltersDrawer = () => {
    setFilterOpen(true);
  };

  const handleCloseFiltersDrawer = () => {
    setFilterOpen(false);
  };

  const changeSearch = (search: string) => {
    if (search) {
      history.push(`${url}${getQueriesAsSearch({ ...omit(params, 'page'), search })}`);
    } else {
      history.push(`${url}${getQueriesAsSearch(omit(params, 'search'))}`);
    }
  };

  const handleRoleChange = (view: MarketplaceMembersViewType) => {
    history.push(`${url}${getQueriesAsSearch({ ...params, role: view })}`);
  };

  const handleClearFilters = () => {
    handleCloseFiltersDrawer();
    history.push(`${url}${getQueriesAsSearch(omit(params, marketplaceMembersParamKeys))}`);
  };

  const handleChangeFilters = (filters: MarketplaceMembersFilteringParams) => {
    handleCloseFiltersDrawer();
    history.push(`${url}${getQueriesAsSearch({ ...omit(params, marketplaceMembersParamKeys), ...filters })}`);
  };

  const actions = useMemo(() => {
    const params = getQueries();

    const requestParams = {
      ...params,
      ...(params?.societies && role === MarketplaceMembersViewType.committee && { role }),
    };

    const actions = [
      {
        text: 'Download Agent Directory',
        icon: <SaveAltRoundedIcon className={classes.dropdownBtnIcon} />,
        onClick: () => window.open(`${getBaseUrl()}society/members/export/xlsx${getQueriesAsSearch(requestParams)}`),
      },
    ];

    if (societyIds.find((id) => id == Societies.IAS)) {
      actions.push({
        text: 'Download Client Directory',
        icon: <SaveAltRoundedIcon className={classes.dropdownBtnIcon} />,
        onClick: () => window.open(`${getBaseUrl()}society/clients/export/xlsx${getQueriesAsSearch(requestParams)}`),
      });
    }

    return actions;
  }, [classes.dropdownBtnIcon, currentPage, orderBy, role, search, societyIds]);

  const tabsConfig: TabConfig[] = useMemo(() => {
    return [
      { label: 'All', value: '' },
      ...societyIds.map((id) => ({
        label: societiesHash[id].displayName,
        mode: TabModes.partialParams,
        value: getQueriesAsSearch({ societies: id.toString() }),
      })),
    ];
  }, [societyIds]);

  return (
    <>
      <div className={classes.root}>
        <PageHeader
          title={'Marketplace Members'}
          actions={<Dropdown mainBtnText={t('actions')} items={actions} color="secondary" />}
          navigation={<Navigation url={url} tabsConfig={tabsConfig} withPageSelector={false} />}
        />
        <Flex alignItems="center" justifyContent="space-between" wrap="nowrap" classes={{ root: classes.filters }}>
          <Flex wrap="nowrap">
            {hasSocietiesParam && (
              <>
                <ViewButtons
                  disabled={state.loading}
                  onClick={handleRoleChange}
                  activeView={role}
                  buttonsConfig={viewButtonConfig}
                  classes={{ root: classes.viewBtn }}
                />
                <div className={classes.divider} />
              </>
            )}
            <CollapsingSearch
              classes={{ searchFieldInput: classes.searchFieldInput, root: classes.search }}
              placeholder={t('xtextx_ellipsis', { text: 'Search for a member' })}
              search={search}
              searchOpen={searchOpen}
              onSearchChange={changeSearch}
              setSearchOpen={setSearchOpen}
            />
            {!searchOpen && (
              <FilterButton
                count={filtersCount}
                onClick={handleOpenFiltersDrawer}
                classes={{ root: classes.filterBtn }}
              />
            )}
          </Flex>
          <Flex wrap="nowrap">
            <SingleSelect border={false} options={sortOptions} value={orderBy} onChange={handleSortingChange} />
            <Pagination
              from={state?.pagination?.from}
              to={state?.pagination?.to}
              currentPage={state?.pagination?.current_page}
              totalItems={state?.pagination?.total}
              totalPages={state?.pagination?.total_pages}
              onPageChange={handlePageChange}
              classes={{ root: classes.pagination }}
            />
          </Flex>
        </Flex>
        {state.loading ? (
          <div className={classes.rootLoading}>
            <Loading absolute />
          </div>
        ) : (
          <Flex wrap="wrap" className={classes.content}>
            {state.list.length ? (
              state.list.map((member) => <MarketplaceMemberCard member={member} key={member.id} />)
            ) : (
              <NoData
                theme={NoDataTheme.Page}
                icon={<NoDataIcon />}
                header={t('we_cant_find_any_members_that_match_your_search_criteria')}
                text={t('adjust_your_search_filters_to_find_other_members')}
                classes={{ root: classes.noDataView }}
              >
                <Flex>
                  <Button text={t('open_filters')} onClick={handleOpenFiltersDrawer} />
                </Flex>
              </NoData>
            )}
          </Flex>
        )}
      </div>
      <MarketplaceMembersFilterDrawer
        params={params}
        onSave={handleChangeFilters}
        onClear={handleClearFilters}
        onClose={handleCloseFiltersDrawer}
        open={filterOpen}
      />
    </>
  );
};

export const MarketplaceMembersIndex = withStyles(styles)(MarketplaceMembersIndexComponent);
