import { MarketplaceMembersFilteringParams } from '@modules/marketplace-members/pages/marketplace-members-index';
import { removeEmptyQueries } from '@shared/utils/common';

import { FilteringInputState, FilteringSelectorState } from './MarketplaceMembersFilterDrawer.types';

export const compileFilterDataFromStates = (
  selectors: FilteringSelectorState,
  inputs: FilteringInputState
): MarketplaceMembersFilteringParams => {
  const params: MarketplaceMembersFilteringParams = {};

  params.companies = selectors.companies.join(',');
  params.regions = selectors.regions.join(',');
  params.societies = selectors.societies.join(',');
  params.committee_regions = selectors.committee_regions.join(',');

  params.name = inputs.name;
  params.email = inputs.email;

  return removeEmptyQueries(params);
};
