import { Theme } from '@core/theme/types/main';
import { colors } from '@core/theme/units/palette';
import { createStyles } from '@core/theme/utils/create-styles';

const headerHeight = 76;

export const styles = ({ spacing }: Theme) =>
  createStyles({
    paper: {
      width: 400,
      maxWidth: '100%',
    },
    root: {
      overflow: 'hidden',
      height: '100%',
    },
    header: {
      height: headerHeight,
      position: 'relative',
      zIndex: 2,
    },
    content: {
      padding: spacing(5, 7, 30),
      height: `calc(100% - ${headerHeight}px)`,
      overflow: 'auto',
      backgroundColor: colors.grey.body,
      zIndex: 1,
    },
    filter: {
      marginBottom: spacing(5),
    },
    autocompleteOptionName: {
      fontSize: 12,
    },
    autocompleteOptionInfo: {
      fontSize: 11,
    },
    filterIcon: {
      color: colors.grey.lightish,
      fontSize: 14,
      marginRight: 4,
      marginTop: 6,
    },
    footer: {
      padding: spacing(5),
      backgroundColor: colors.grey.body,
      position: 'absolute',
      bottom: 0,
      zIndex: 2,
      borderTop: `1px solid ${colors.grey.lightish}`,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
  });
