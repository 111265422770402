import 'react-hot-loader/patch';
import './polyfill';
import 'reflect-metadata';
import './core/i18n';

import 'reset-css';
import 'font-awesome/css/font-awesome.min.css';

import { QueryClientProvider } from '@tanstack/react-query';
import { render } from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router-dom';

import { ConfigProvider } from '@core/contexts/ConfigContext';
import { FieldsProvider } from '@core/contexts/FieldsContext';
import { OptionsProvider } from '@core/contexts/OptionsContext';
import { UnsavedChangesProvider } from '@core/contexts/UnsavedChangesContext';
import { queryClient } from '@core/http-client';
import { createTheme } from '@core/theme';
import { ThemeProvider } from '@core/theme/provider';
import { CssBaseline } from '@core/theme/utils/css-baseline';
import store from '@redux/store';
import { Confirmation } from '@shared/components/confirmation';
import { Notification } from '@shared/components/notification';
import { history } from '@shared/utils/history';

import App from './app';

import './assets/scss/iconFonts.scss';
import './assets/scss/mapOverride.scss';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

const theme = createTheme();

render(
  <Router history={history}>
    <ThemeProvider theme={theme}>
      <ReduxProvider store={store}>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider>
            <UnsavedChangesProvider>
              <OptionsProvider>
                <FieldsProvider>
                  <CssBaseline />
                  <Confirmation />
                  <Notification />
                  <App />
                </FieldsProvider>
              </OptionsProvider>
            </UnsavedChangesProvider>
          </ConfigProvider>
        </QueryClientProvider>
      </ReduxProvider>
    </ThemeProvider>
  </Router>,
  document.getElementById('root')
);
