import { FC, useMemo } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';

import { styles } from './MarketplaceMembers.styles';
import { MarketplaceMembersIndex } from './pages/marketplace-members-index/index';

export interface MarketplaceMembersProps extends WithStyles<typeof styles>, RouteComponentProps {}

const MarketplaceMembers: FC<MarketplaceMembersProps> = ({ classes, match: { url } }) => {
  const routes = useMemo(() => {
    return [
      {
        path: url,
        render: () => <MarketplaceMembersIndex url={url} />,
      },
    ];
  }, [url]);

  return (
    <div className={classes.root}>
      {/* Router */}
      <Switch>
        {routes.map((routeProps) => (
          <Route key={routeProps.path} {...routeProps} />
        ))}
        <Redirect to={url} />
      </Switch>
    </div>
  );
};

export default withStyles(styles)(MarketplaceMembers);
